$white: #fff;
$gray-100: #e4e5e7;
$gray-200: #cacad0;
$gray-300: #afb0b8;
$gray-400: #9495a1;
$gray-500: #7a7b89;
$gray-600: #5f6072;
$gray-700: #44465a;
$gray-800: #2a2b43;
$gray-900: #0f112b;
$black: #000;


$blue: #1861f9;
$indigo: #643df9;
$purple: #b018f9;
$pink: 	#f91861;
$red: #f94018;
$orange: #f9b018;
$yellow: #d2f918;
$green: #0f856f;
$teal: #14abb4;
$cyan: #18d1f9;

$primary: mix($blue, $green);
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

$light: $gray-100;
$dark: $gray-900;

$color-contrast-dark: $black;
$color-contrast-light: $white;
