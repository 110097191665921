.select2-container--bootstrap-5 {
 .select2-selection--multiple {
  // List items
  .select2-selection__rendered {
   display: flex;
   flex-direction: row;
   gap: .5rem;
   flex-wrap: wrap;
   padding-left: 0;
   margin: 0;
   list-style: none;

   // Items
   .select2-selection__choice {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $s2bs5-multi-item-padding-y $s2bs5-multi-item-padding-x;
    @include font-size($s2bs5-multi-item-font-size);
    color: $s2bs5-color;
    font-weight: 500;
    cursor: auto;
    border: 0;
    background-color: rgba($s2bs5-border-color, .5);
    @include border-radius($s2bs5-border-radius);

    // Removal button
    .select2-selection__choice__remove {
     --equal: 20px;
     width: var(--equal);
     height: var(--equal);
     padding: $s2bs5-clear-padding-y $s2bs5-clear-padding-x;
     margin-right: .25rem;
     overflow: hidden;
     text-indent: 100%;
     white-space: nowrap;
     background-color: transparent;
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='currentColor' d='M0 128v256c0 35.38 28.62 64 64 64h306.8C387.8 448 404 441.3 416 429.3l150.6-150.6c12.5-12.5 12.5-32.75 0-45.25L416 82.75C404 70.75 387.8 64 370.8 64H64C28.63 64 0 92.63 0 128zM190.1 255.1L143 208.1c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L224 222.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0s9.375 24.56 0 33.94L257.9 255.1l47.03 47.03c9.375 9.375 9.375 24.56 0 33.94c-9.373 9.373-24.56 9.381-33.94 0L224 289.9l-47.03 47.03c-9.373 9.373-24.56 9.381-33.94 0c-9.375-9.375-9.375-24.56 0-33.94L190.1 255.1z'%3E%3C/path%3E%3C/svg%3E");
     background-repeat: no-repeat;
     border: 0;

     &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%23f94018' d='M0 128v256c0 35.38 28.62 64 64 64h306.8C387.8 448 404 441.3 416 429.3l150.6-150.6c12.5-12.5 12.5-32.75 0-45.25L416 82.75C404 70.75 387.8 64 370.8 64H64C28.63 64 0 92.63 0 128zM190.1 255.1L143 208.1c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L224 222.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0s9.375 24.56 0 33.94L257.9 255.1l47.03 47.03c9.375 9.375 9.375 24.56 0 33.94c-9.373 9.373-24.56 9.381-33.94 0L224 289.9l-47.03 47.03c-9.373 9.373-24.56 9.381-33.94 0c-9.375-9.375-9.375-24.56 0-33.94L190.1 255.1z'%3E%3C/path%3E%3C/svg%3E");
     }

     // Remove children
     >span {
      display: none;
     }
    }
   }
  }

  // Input area
  .select2-search {
   display: block;
   width: 100%;
   height: $s2bs5-height-inner;

   // Input field
   .select2-search__field {
    width: 100%;
    height: $s2bs5-height-inner;
    margin-top: 0;
    margin-left: 0;
    font-family: $s2bs5-font-family;
    line-height: $s2bs5-line-height;
    background-color: transparent;
   }
  }

  // Clear button override
  .select2-selection__clear {
   right: $s2bs5-padding-x;
  }
 }
}